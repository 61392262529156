import React, { useEffect, useState } from 'react';
import { db, auth } from '../../config/firebase';
import { collection, doc, getDocs, setDoc, increment } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import styles from './Earnings.module.css';
import { onAuthStateChanged } from "firebase/auth";

export function Earnings() {
  const [monthlyEarnings, setMonthlyEarnings] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [amount, setAmount] = useState('');
  const [selectedAction, setSelectedAction] = useState('Increase');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [loading, setLoading] = useState(true);  // New loading state
  const navigate = useNavigate();

  const authorizedEmail = "speed.webapp@gmail.com";

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user?.email === authorizedEmail) {
        setLoading(false);  // Set loading to false once the user is verified
        fetchEarnings();
      } else {
        navigate('/trackers');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchEarnings = async () => {
    try {
      const earningsCollection = collection(db, 'monthlyEarnings');
      const earningsSnapshot = await getDocs(earningsCollection);
      let total = 0;
      const earningsData = earningsSnapshot.docs.map(doc => {
        const data = doc.data();
        total += data.totalAmount || 0;
        return { id: doc.id, ...data };
      });
      setMonthlyEarnings(earningsData);
      setTotalEarnings(total);
    } catch (error) {
      console.error('Error fetching monthly earnings:', error);
    }
  };

  const handleUpdate = async () => {
    if (!amount || !selectedMonth) return;

    const modifier = selectedAction === 'Expense' ? -1 : 1;
    const adjustedAmount = modifier * parseFloat(amount);

    try {
      const monthlyEarningsRef = doc(db, 'monthlyEarnings', selectedMonth);
      await setDoc(monthlyEarningsRef, { totalAmount: increment(adjustedAmount) }, { merge: true });

      setAmount('');
      fetchEarnings();  // Refresh earnings list and total
    } catch (error) {
      console.error('Error updating monthly earnings:', error);
    }
  };

  if (loading) return null;  // Prevent rendering if still loading

  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>Monthly Earnings</h2>
      
      <ul className={styles.earningsList}>
        {monthlyEarnings.map(({ id, totalAmount }) => (
          <li key={id} className={styles.earningsItem}>
            {id}: Tk {totalAmount}
          </li>
        ))}
      </ul>
      
      <h3 className={styles.totalEarnings}>Total Earnings: Tk {totalEarnings}</h3>

      <div className={styles.controlsContainer}>
        <label className={styles.label}>
          Select Month:
          <select 
            className={styles.selectInput}
            value={selectedMonth} 
            onChange={(e) => setSelectedMonth(e.target.value)}
            required
          >
            <option value="">Select Month</option>
            {monthlyEarnings.map(({ id }) => (
              <option key={id} value={id}>{id}</option>
            ))}
          </select>
        </label>

        <label className={styles.label}>
          Amount:
          <input
            type="number"
            className={styles.textInput}
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter amount"
            required
          />
        </label>

        <label className={styles.label}>
          Action:
          <select 
            className={styles.selectInput}
            value={selectedAction} 
            onChange={(e) => setSelectedAction(e.target.value)}
          >
            <option value="Increase">Increase</option>
            <option value="Expense">Expense</option>
          </select>
        </label>

        <button className={styles.actionButton} onClick={handleUpdate}>Update Earnings</button>
      </div>
    </div>
  );
}

export default Earnings;
