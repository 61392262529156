import React, { useState, useEffect, useCallback, useRef } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify'; // Import ToastContainer and toast
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import {
  addProxyToFirestore,
  updateProxyInFirestore,
  removeProxyFromFirestore,
} from "./AdminCountAuthFirestore/firestoreService";
import { handleLogout } from "./AdminCountAuthFirestore/authService";
import { auth as firebaseAuth, db, onAuthStateChanged } from "../../config/firebase";
import {
  doc,
  onSnapshot,
  collection,
  addDoc,
  getDoc,
  query,
  where,
  getDocs,
  orderBy,
  limit,
  startAfter,
  getCountFromServer // Import getCountFromServer
} from "firebase/firestore";
import './Dashboard.css';
import { Navbar } from './Navbar';
import { UserInfo } from './UserInfo';
import { InputSection } from './InputSection';
import { ProxyList } from './ProxyList';
import { Pagination } from './Pagination';
import QuotaResetTimer from "../dashboard/QuotaCount/QuotaResetTimer";

export const Dashboard = () => {
  // State Variables
  const [password, setPassword] = useState("");
  const [speed, setSpeed] = useState("");
  const [expiry, setExpiry] = useState("30");
  const [proxyList, setProxyList] = useState([]);
  const [userId, setUserId] = useState("");
  const [balance, setBalance] = useState(0);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [isLoggedOut, setIsLoggedOut] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Only for generate/regenerate
  const [proxyCount, setProxyCount] = useState(0); // Total proxy count
  const [price, setPrice] = useState(0);
  const [proxyPage, setProxyPage] = useState(0);
  const [isRegenerating, setIsRegenerating] = useState(false);
  const [displayUsername, setDisplayUsername] = useState("");
  const [usernameInput, setUsernameInput] = useState("");
  const [currentRegeneratingProxyId, setCurrentRegeneratingProxyId] = useState(null);
  const [selectedRegenerateIP, setSelectedRegenerateIP] = useState("");
  const debounceTimeout = useRef(null);
  const [proxyCooldowns, setProxyCooldowns] = useState({});
  const [availableIPs, setAvailableIPs] = useState([]);
  const [selectedIP, setSelectedIP] = useState("");
  const [protocol, setProtocol] = useState('socks5');
  const [isFetchingIPs, setIsFetchingIPs] = useState(false); // Add a new state variable for loading
  const [ftpEnabled, setFtpEnabled] = useState(false); // Add FTP state

  const proxiesPerPage = 20; // Adjust as needed
  const historyRef = useRef([]); // Use ref to track cursors without causing re-renders
  const lastVisibleRef = useRef(null); // Use ref for lastVisible to prevent useEffect re-runs
  const [hasNextPage, setHasNextPage] = useState(false); // To manage "Next" button
  const [searchTerm, setSearchTerm] = useState(""); // Add this line to your state management

  // Define isServerSelectionRequired
  const isServerSelectionRequired = protocol === 'l2tp' || protocol === 'pptp' || ftpEnabled;

  // Function to show toast messages
  const showToast = (message, type) => {
    toast(message, {
      type,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: {
        background: "rgba(0, 0, 0, 0.7)",
        color: "#fff"
      }
    });
  };

  // Test toast on component mount
  useEffect(() => {
    showToast("Dashboard Loaded Successfully!", "success");
  }, []);

  // Add a useEffect to listen for message changes and display toasts
  useEffect(() => {
    if (message) {
      showToast(message, messageType);
      setMessage(""); // Reset message after showing toast
    }
  }, [message, messageType]);

  // --------------------------
  // 1. Fetch User Details with onSnapshot
  // --------------------------
  useEffect(() => {
    if (!userId) return;

    const userDocRef = doc(db, "users", userId);
    const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
      if (docSnap.exists()) {
        const userData = docSnap.data();

        setDisplayUsername(userData.username);
        setBalance(userData.balance);
      }
    }, (error) => {
      console.error("Failed to fetch user details:", error);
      setMessage("Failed to fetch user details. Please try again later.");
      setMessageType("error");
    });

    return () => {

      unsubscribe();
    };
  }, [userId]);

  useEffect(() => {
    // Function to get a new token when the component loads
    const fetchToken = async (user) => {
      if (user) {
        const newToken = await user.getIdToken(true); // 'true' forces token refresh
        localStorage.setItem("jwtToken", newToken); // Update token in localStorage
      }
    };

    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
      if (user) {
        fetchToken(user);
      } else {
        console.error("User is not authenticated");
      }
    });

    return () => unsubscribe(); // Clean up the listener on unmount
  }, []);

  // --------------------------
  // 2. Fetch Total Proxy Count
  // --------------------------
  useEffect(() => {
    const fetchProxyCount = async () => {
      if (!userId) return;


      try {
        const proxiesCollectionRef = collection(db, "users", userId, "proxies");
        const countQuery = query(proxiesCollectionRef);
        const snapshot = await getCountFromServer(countQuery);
        setProxyCount(snapshot.data().count);

      } catch (error) {
        console.error("Failed to fetch proxy count:", error);
      }
    };

    fetchProxyCount();
  }, [userId]);

  // --------------------------
  // 3. Fetch Proxies with Pagination
  // --------------------------
  const fetchProxies = useCallback(async () => {
    if (!userId) return;


    // Do not set isLoading here to avoid showing loader on page change

    try {
      const proxiesCollectionRef = collection(db, "users", userId, "proxies");
      let proxiesQuery = query(
        proxiesCollectionRef,
        orderBy("generatedDate", "desc"),
        limit(proxiesPerPage)
      );

      // If not the first page, use startAfter with the cursor from historyRef
      if (proxyPage > 0 && historyRef.current[proxyPage - 1]) {

        proxiesQuery = query(
          proxiesCollectionRef,
          orderBy("generatedDate", "desc"),
          startAfter(historyRef.current[proxyPage - 1]),
          limit(proxiesPerPage)
        );
      }

      const snapshot = await getDocs(proxiesQuery);
      const proxies = [];
      const cooldowns = {};

      snapshot.forEach((docSnap) => {
        const proxy = { id: docSnap.id, ...docSnap.data() };
        proxies.push(proxy);

        if (proxy.countdownEnd && new Date(proxy.countdownEnd) > new Date()) {
          cooldowns[proxy.id] = true;
        } else {
          cooldowns[proxy.id] = false;
        }
      });

      setProxyList(proxies);
      setProxyCooldowns(cooldowns);

      const last = snapshot.docs[snapshot.docs.length - 1];
      if (last) {

        if (historyRef.current.length === proxyPage) {
          // Only add to history if it's a new page
          historyRef.current.push(last);

        } else {
          // Update history for the current page if navigating back
          historyRef.current[proxyPage] = last;

        }
        lastVisibleRef.current = last;
        // Determine if there is a next page
        setHasNextPage(snapshot.size === proxiesPerPage);
      } else {

        setHasNextPage(false);
      }

      // // Simulate 'resource-exhausted' error for testing purposes
      // const simulatedError = new Error("Simulated resource-exhausted error");
      // simulatedError.code = 'resource-exhausted';
      // throw simulatedError;

    } catch (error) {
      console.error("Error fetching proxies:", error);

      const errorCode = error.code || error.response?.data?.code;

      if (errorCode === 'resource-exhausted') {
        setMessageType("error");
        setMessage(<QuotaResetTimer />);
      } else {
        setMessage("Failed to fetch proxy list. Please try again later.");
        setMessageType("error");
      }
    }
  }, [userId, proxyPage, proxiesPerPage]);

  // Fetch Proxies when userId or proxyPage changes
  useEffect(() => {
    if (userId) {
      fetchProxies();
    }
  }, [userId, proxyPage, fetchProxies]);

  // --------------------------
  // 4. Check if Username Exists
  // --------------------------
  const checkUsernameExists = async (username) => {

    const q = query(collection(db, "users", userId, "proxies"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    const exists = !querySnapshot.empty;

    return exists;
  };

  // --------------------------
  // 5. Auth State Change Listener
  // --------------------------
  useEffect(() => {

    const unsubscribe = firebaseAuth.onAuthStateChanged((user) => {
      if (user) {

        setUserId(user.uid);
      } else {

        setUserId("");
        setIsLoggedOut(true);
      }
      setIsLoading(false);
    });

    return () => {

      unsubscribe();
    };
  }, []);

  // --------------------------
  // 6. Fetch Available IPs from Backend
  // --------------------------
  useEffect(() => {
    const fetchAvailableIPs = async () => {
      setIsFetchingIPs(true); // Start loading
      try {
        const response = await axios.get("https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/get_available_ips");
        if (response.data.success) {
          const ips = response.data.ips; // Now includes ip, speed, and for_l2tp_pptp
          setAvailableIPs(ips);
        } else {
          setMessage("Failed to fetch available IPs.");
          setMessageType("error");
          setTimeout(() => setMessage(""), 9000);
        }
      } catch (error) {
        console.error("Failed to fetch available IPs:", error);
        setMessage("Failed to fetch available IPs. Please try again later.");
        setMessageType("error");
        setTimeout(() => setMessage(""), 9000);
      } finally {
        setIsFetchingIPs(false); // End loading
      }
    };

    fetchAvailableIPs();
  }, [protocol]);

  useEffect(() => {
    if (protocol === 'pptp' || protocol === 'l2tp') {
      setExpiry("30");
    }
  }, [protocol]);

  // --------------------------
  // 7. Calculate Price Based on Speed and Expiry
  // --------------------------
  useEffect(() => {
    if (!speed || !expiry) {
      setPrice(0);
      return;
    }
  
    const speedMbps = parseInt(speed, 10);
    const expiryDays = parseInt(expiry, 10);
    let fixedPrice = 0;
  
    if (!isNaN(speedMbps)) {
      if (protocol === 'pptp' || protocol === 'l2tp') {
        // Pricing for PPTP or L2TP
        if (speedMbps === 100) fixedPrice = 300;
        else if (speedMbps === 50) fixedPrice = 200;
        else if (speedMbps === 30) fixedPrice = 150;
        else if (speedMbps === 20) fixedPrice = 120;
      } else {
        // Existing pricing for other protocols
        if (expiryDays === 30) {
          if (speedMbps === 100) fixedPrice = 150;
          else if (speedMbps === 60) fixedPrice = 130;
          else if (speedMbps === 50) fixedPrice = 100;
          else if (speedMbps === 40) fixedPrice = 90;
          else if (speedMbps === 30) fixedPrice = 70;
          else if (speedMbps === 20) fixedPrice = 50;
        } else if (expiryDays === 15) {
          if (speedMbps === 100) fixedPrice = 120;
          else if (speedMbps === 60) fixedPrice = 100;
          else if (speedMbps === 50) fixedPrice = 80;
          else if (speedMbps === 40) fixedPrice = 70;
          else if (speedMbps === 30) fixedPrice = 60;
          else if (speedMbps === 20) fixedPrice = 40;
        } else if (expiryDays === 7) {
          if (speedMbps === 100) fixedPrice = 100;
          else if (speedMbps === 60) fixedPrice = 90;
          else if (speedMbps === 50) fixedPrice = 70;
          else if (speedMbps === 40) fixedPrice = 60;
          else if (speedMbps === 30) fixedPrice = 50;
          else if (speedMbps === 20) fixedPrice = 30;
        }
      }
    }
  
    // Increase price by 30 if selected IP is FTP
    const selectedIpIsFtp =
      availableIPs.find((ip) => ip.ip === selectedIP)?.is_ftp || false;
    if (selectedIpIsFtp) {
      fixedPrice += 30;
    }
  
    setPrice(fixedPrice);
  }, [speed, expiry, protocol, selectedIP, availableIPs]);
  

  // --------------------------
  // 8. Handle Proxy Generation
  // --------------------------
  const lock = useRef(false);
const handleGenerateProxy = async (ftpEnabled) => {
    // Prevent multiple clicks when loading
    if (isRegenerating || isLoading || lock.current) return;

    setIsLoading(true); // Start loading and disable the button
    lock.current = true;  // Lock the request to avoid multiple clicks

    // Clear any existing debounce timeout (if user clicks rapidly)
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    const speedMbps = parseInt(speed, 10);
    const expiryDays = parseInt(expiry, 10);

    // Input Validation
  if (!usernameInput || !password || isNaN(speedMbps)) {
    setMessage("All fields are required.");
    setMessageType("error");
    showToast("All fields are required.", "error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  // New validation for protocol selection
  if (!protocol) {
    setMessage("Select a protocol.");
    setMessageType("error");
    showToast("Select a protocol.", "error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput === password) {
    setMessage("Username and password cannot be the same.");
    setMessageType("error");
    showToast("Username and password cannot be the same.", "error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput.includes(" ")) {
    setMessage("Username cannot contain spaces.");
    setMessageType("error");
    showToast("Username cannot contain spaces.", "error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (/[^a-zA-Z0-9._-]/.test(usernameInput)) {
    setMessage("Username can only contain letters, numbers, dots, underscores, and hyphens.");
    setMessageType("error");
    showToast("Username can only contain letters, numbers, dots, underscores, and hyphens.", "error");
    setTimeout(() => setMessage(""), 15000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput.startsWith(".") || usernameInput.endsWith(".")) {
    setMessage("Username cannot start or end with a dot.");
    setMessageType("error");
    showToast("Username cannot start or end with a dot.", "error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  if (usernameInput.includes("@")) {
    setMessage("Username cannot contain an @.");
    setMessageType("error");
    showToast("Username cannot contain an @.", "error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;  // Unlock after error
    return;
  }

  // Add validation for server selection
  if (isServerSelectionRequired && !selectedIP) {
    if (ftpEnabled) {
      setMessage("Please select a server when FTP is enabled.");
      showToast("Please select a server when FTP is enabled.", "error");
    } else {
      setMessage("Please select a server for the chosen protocol.");
      showToast("Please select a server for the chosen protocol.", "error");
    }
    setMessageType("error");
    setTimeout(() => setMessage(""), 9000);
    setIsLoading(false);
    lock.current = false;
    return;
  }

    if (balance < price) {
      setMessage("Insufficient balance.");
      setMessageType("error");
      showToast("Insufficient balance.", "error");
      setTimeout(() => setMessage(""), 9000);
      setIsLoading(false);
      lock.current = false;  // Unlock after error
      return;
    }

    console.log("All validations passed. Proceeding to generate proxy.");
    const usernameExists = await checkUsernameExists(usernameInput);
    if (usernameExists) {
      setMessage("Username already exists. Please try something else.");
      setMessageType("error");
      showToast("Username already exists. Please try something else.", "error");
      setTimeout(() => setMessage(""), 9000);
      setIsLoading(false);
      lock.current = false;  // Unlock after error
      return;
    }

    const currentDate = new Date();
    const expiryDate = new Date(currentDate);
    expiryDate.setDate(currentDate.getDate() + expiryDays);

    try {
      // Include the userId in the request data
      const requestData = {
        username: usernameInput,
        password,
        speed: `${speedMbps}`,
        userId: userId,  // Add userId here
        protocol, // Use 'protocol' instead of isPPTPEnabled and isL2TPEnabled
      };

      if (selectedIP) {
        requestData.ip = selectedIP;
      }

      console.log("Sending request to generate proxy:", requestData);

      // Retrieve the JWT from local storage
      const token = localStorage.getItem('jwtToken'); // Retrieve the token
      if (!token) {
          setMessage("User not authenticated. Please log out and log in again.");
          setMessageType("error");
          showToast("User not authenticated. Please log out and log in again.", "error");
          setIsLoading(false);
          lock.current = false;  // Unlock after error
          return;
      }


      // Set the token in the Authorization header
        let apiEndpoint = "https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/generate_proxy";

        if (protocol === 'l2tp') {
          apiEndpoint = "https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/generate_l2tp";
        } else if (protocol === 'pptp') {
          apiEndpoint = "https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/generate_pptp";
        }

        const response = await axios.post(apiEndpoint, requestData, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

      if (response.data.success) {
        console.log("Proxy generated successfully:", response.data.proxy);
        const proxyData = {
          ...response.data.proxy,
          expiryDate: expiryDate.toISOString(),
          generatedDate: currentDate.toISOString(),
          status: 'active',
          countdownEnd: null,
          // Use the FTP status from the response instead of the checkbox
          isFtp: response.data.proxy.isFtp
        };
        // Removed 'port' from proxyData
        const newBalance = balance - price;
        await addProxyToFirestore(userId, proxyData, newBalance);
        setBalance(newBalance);
        setMessage("Proxy generated successfully!");
        setMessageType("success");
        showToast("Proxy generated successfully!", "success");
        // Fetch and refresh proxies after generation
        await fetchProxies();  // Ensure this is awaited so that the dashboard updates fully before re-enabling the button
        setUsernameInput(""); // Reset input
        setProxyCount(prevCount => prevCount + 1);
      } else {
        console.error("Proxy generation failed:", response.data.error);
        throw new Error(response.data.error);
      }
    } catch (error) {
      console.error("Error generating proxy:", error);
      setMessage(error.response?.data?.error || "Failed to generate proxy. Please try again later.");
      setMessageType("error");
      showToast(error.response?.data?.error || "Failed to generate proxy. Please try again later.", "error");
    } finally {
      // Keep the button disabled until after the proxy list is fetched
      debounceTimeout.current = setTimeout(() => {
        setIsLoading(false);
        lock.current = false;   // Unlock the button after a delay
      }, 2000); // Add a slight delay after proxy is generated and dashboard is updated

      setTimeout(() => setMessage(""), 9000);  // Clear messages after 9 seconds
    }
};

  // --------------------------
  // 9. Handle Proxy Regeneration
  // --------------------------
  const handleRegenerateProxy = async (proxyId, oldIp, regenerateIP, proxyProtocol) => { // Renamed parameter to proxyProtocol
    if (isRegenerating || proxyCooldowns[proxyId]) return; // Prevent multiple regenerations

    // Check if the selected IP is the same as the old IP
    if (regenerateIP === oldIp) {
      setMessage("The selected IP is the same as the current IP. Please select a different IP.");
      setMessageType("error");
      showToast("The selected IP is the same as the current IP. Please select a different IP.", "error");
      setTimeout(() => setMessage(""), 9000);
      return; // Stop further execution
    }

    setIsRegenerating(true); // Indicate regeneration is in progress
    setIsLoading(true); // Show loading for regenerate

    try {
      const proxyDocRef = doc(db, "users", userId, "proxies", proxyId);
      const proxyDoc = await getDoc(proxyDocRef);

      if (!proxyDoc.exists()) {
        setMessage("Proxy not found.");
        setMessageType("error");
        showToast("Proxy not found.", "error");
        setIsLoading(false);
        setIsRegenerating(false);
        setTimeout(() => setMessage(""), 9000);
        return;
      }

      const proxy = proxyDoc.data();

      if (proxy.status === 'expired') {
        setMessage("Expired Proxy. Generate a New Proxy");
        setMessageType("error");
        showToast("Expired Proxy. Generate a New Proxy", "error");
        setIsLoading(false);
        setIsRegenerating(false);
        setTimeout(() => setMessage(""), 9000);
        return;
      }

      const { username, password, speed } = proxy;

      const requestData = {
        username,
        old_ip: oldIp,
        password,
        speed,
        userId: userId, // Ensure this is correctly defined
        ip: regenerateIP // If applicable
    };

      if (regenerateIP) { // Use the IP selected from the dashboard section
        requestData.ip = regenerateIP;
      }

      // Retrieve the JWT from local storage
      const token = localStorage.getItem('jwtToken'); // Retrieve the token
      if (!token) {
          setMessage("User not authenticated. Please logout and log in again.");
          setMessageType("error");
          showToast("User not authenticated. Please logout and log in again.", "error");
          setIsLoading(false);
          lock.current = false;  // Unlock after error
          return;
      }

      // Set the API endpoint based on the proxyProtocol
      let apiEndpoint = 'https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/regenerate_proxy';
      if (proxyProtocol === 'l2tp') {
        apiEndpoint = 'https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/regenerate_l2tp';
      } else if (proxyProtocol === 'pptp') {
        apiEndpoint = 'https://proxygen-api-v2-pptpl2tpftp-eddition.onrender.com/regenerate_pptp';
      }

      const response = await axios.post(apiEndpoint, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.success) {

        const countdownDuration = 1.5e+6; // 25 minutes
        const countdownEnd = new Date(Date.now() + countdownDuration);

        const newProxyData = {
          ...response.data.proxy,
          ip: response.data.proxy.ip,
          countdownEnd: countdownEnd.toISOString()
          // Removed 'port' from newProxyData
        };

        await updateProxyInFirestore(userId, proxyId, newProxyData);

        // Update cooldown state for this proxy
        setProxyCooldowns((prevState) => ({
          ...prevState,
          [proxyId]: true,
        }));

        setMessage("Proxy regenerated successfully!");
        setMessageType("success");
        showToast("Proxy regenerated successfully!", "success");
        // Refresh proxies after regeneration
        fetchProxies();
      } else {
        console.error("Proxy regeneration failed:", response.data.error);
        setMessage(response.data.error || "Failed to regenerate proxy.");
        setMessageType("error");
        showToast(response.data.error || "Failed to regenerate proxy.", "error");
      }
    } catch (error) {
      console.error("Error regenerating proxy:", error);
      setMessage(error.response?.data?.error || "Failed to regenerate proxy. Please try again later.");
      setMessageType("error");
      showToast(error.response?.data?.error || "Failed to regenerate proxy. Please try again later.", "error");
    } finally {
      setIsLoading(false);
      setIsRegenerating(false);
      setTimeout(() => setMessage(""), 9000);
    }
  };

  // --------------------------
  // 10. Handle Proxy Removal
  // --------------------------
  const handleRemoveProxy = async (proxyId) => {
    const proxy = proxyList.find(p => p.id === proxyId);
    if (!proxy) return;

    try {

      await removeProxyFromFirestore(userId, proxyId);

      const expiredProxyData = {
        ...proxy,
        status: 'expired'
      };
      await addDoc(collection(db, "users", userId, "expiredProxies"), expiredProxyData);

      setMessage("Proxy removed successfully!");
      setMessageType("success");
      showToast("Proxy removed successfully!", "success");
      // Refresh proxies after removal
      fetchProxies();
      // Update proxy count
      setProxyCount(prevCount => prevCount - 1);
    } catch (error) {
      console.error("Error removing proxy:", error);
      setMessage("Failed to remove proxy. Please try again later.");
      setMessageType("error");
      showToast("Failed to remove proxy. Please try again later.", "error");
    }

    setTimeout(() => setMessage(""), 9000);
  };

  // --------------------------
  // 11. Handle Countdown End
  // --------------------------
  const handleCountdownEnd = (proxyId) => {
    setProxyCooldowns((prevState) => ({
      ...prevState,
      [proxyId]: false, // Reset cooldown for this proxy
    }));

  };

  // --------------------------
  // 12. Pagination Handlers
  // --------------------------
  const handleNextPage = () => {
    if (hasNextPage) {

      setProxyPage(prevPage => prevPage + 1);
      // 'fetchProxies' will be triggered by 'proxyPage' change
    }
  };

  const handlePreviousPage = () => {
    if (proxyPage > 0) {

      setProxyPage(prevPage => prevPage - 1);
      // 'fetchProxies' will be triggered by 'proxyPage' change
    }
  };

  // --------------------------
  // 13. Helper Function to Get Last Segment of IP
  // --------------------------
  const getLastSegment = (ip) => {
    if (typeof ip !== "string" || !ip) return "";
    const segments = ip.split(".");
    return segments[segments.length - 1];
};


  // --------------------------
  // 14. Loading State for Generate/Regenerate Only
  // --------------------------
  // isLoading is already managed in handleGenerateProxy and handleRegenerateProxy

  // --------------------------
  // 15. Redirect if Logged Out
  // --------------------------
  if (isLoggedOut) {

    return <Navigate to="/" />;
  }

  const handleCloseMessage = () => {
    setMessage(null);
  };

  // --------------------------
  // 16. Displayed Proxies Based on Pagination
  // --------------------------
  const displayedProxies = proxyList;


  return (
    <div className="dashboard-container">
      {/* Navbar Section */}
      <Navbar handleLogout={() => handleLogout(setIsLoggedOut)} />

      {/* User Information Section */}
      <UserInfo
        displayUsername={displayUsername}
        balance={balance}
        proxyCount={proxyCount}
        message={message}
        messageType={messageType}
        handleCloseMessage={handleCloseMessage}
      />

      {/* Input Section */}
      <InputSection
        usernameInput={usernameInput}
        setUsernameInput={setUsernameInput}
        password={password}
        setPassword={setPassword}
        speed={speed}
        setSpeed={setSpeed}
        expiry={expiry}
        setExpiry={setExpiry}
        selectedIP={selectedIP}
        setSelectedIP={setSelectedIP}
        availableIPs={availableIPs} // Ensure availableIPs is passed correctly
        getLastSegment={getLastSegment}
        price={price}
        setPrice={setPrice} // Add this line
        isLoading={isLoading}
        isRegenerating={isRegenerating}
        handleGenerateProxy={handleGenerateProxy}
        protocol={protocol}
        setProtocol={setProtocol}
        isFetchingIPs={isFetchingIPs}
        ftpEnabled={ftpEnabled} // Pass FTP state
        setFtpEnabled={setFtpEnabled} // Pass setter
      />

      {/* Proxy List Section */}
      <ProxyList
        displayedProxies={displayedProxies}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleRemoveProxy={handleRemoveProxy}
        currentRegeneratingProxyId={currentRegeneratingProxyId}
        isRegenerating={isRegenerating}
        proxyCooldowns={proxyCooldowns}
        setCurrentRegeneratingProxyId={setCurrentRegeneratingProxyId}
        availableIPs={availableIPs}
        getLastSegment={getLastSegment}
        isLoading={isLoading}
        handleRegenerateProxy={handleRegenerateProxy}
        selectedRegenerateIP={selectedRegenerateIP}
        setSelectedRegenerateIP={setSelectedRegenerateIP}
        handleCountdownEnd={handleCountdownEnd}
        setMessage={setMessage}
        setMessageType={setMessageType}
      />

      {/* Pagination Controls - Only show when there are 20 or more proxies */}
      {proxyCount >= 20 && (
        <Pagination
          proxyPage={proxyPage}
          hasNextPage={hasNextPage}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      )}

      {/* Toast Container */}
      <ToastContainer />
    </div>
  );
};
