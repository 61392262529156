import React from 'react';
import styles from './Contact.module.css'; // Import the module styles
import Navbar from '../Navbar/bar';

export const Contact = () => {
  return (
    <div>
      <Navbar />
      <div className={styles.contactContainer}>
        <h2>Contacts</h2>
        <ul className={styles.contactList}>
          <li>
            <a href="https://t.me/+IYQiMmNJL8kyZWY9" target="_blank" rel="noopener noreferrer">
              Join Our Telegram Main Group
            </a>
          </li>
          <li>
            <a href="https://t.me/+EtzFLJKNrQQ4NjVl" target="_blank" rel="noopener noreferrer">
              Join Our Telegram Support Group
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Contact;
