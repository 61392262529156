
import React from 'react';

export const Pagination = ({ proxyPage, hasNextPage, handlePreviousPage, handleNextPage }) => (
  <div className="nav-buttons">
    <button onClick={handlePreviousPage} disabled={proxyPage === 0}>
      Previous
    </button>
    <button onClick={handleNextPage} disabled={!hasNextPage}>
      Next
    </button>
  </div>
);