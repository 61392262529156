import React from 'react';
import { Link } from 'react-router-dom';
import { ClaimRewardBox } from './RewardBox/ClaimRewardBox';
import { ReviewPopup } from './ReviewPopup/ReviewPopup';
import { FaTelegram } from 'react-icons/fa';

export const UserInfo = ({ displayUsername, balance, proxyCount, message, messageType, handleCloseMessage }) => {
  return (
    <div className="user-info">
      <a 
        href="https://t.me/speedbuy_xyz" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="telegram-link"
      >
        <FaTelegram />
        <span>Join Us</span>
      </a>
      <ReviewPopup />
      <div>
        <div className="claim-reward-box"><ClaimRewardBox /></div>
        <h1 className="username-animated">
          Welcome, <span className="username-dynamic">{displayUsername}</span>
        </h1>
        <p className="balance">
          {balance === 0 ? (
            <span>
              Balance is 0 Taka. Please <Link to="/addmoney">Add Money</Link>.
            </span>
          ) : (
            `Balance: ${balance} Taka`
          )}
        </p>
        <p className="proxy-count">Total Proxies: {proxyCount}</p>
      </div>
    </div>
  );
};