import { useState, useEffect } from 'react';

export function QuotaResetTimer() {
  const [timeRemaining, setTimeRemaining] = useState('');

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const resetTime = new Date();
      
      resetTime.setHours(13, 0, 0, 0); // Set reset time to 1 PM

      // If current time is past 1 PM, set reset time to 1 PM the next day
      if (now >= resetTime) {
        resetTime.setDate(now.getDate() + 1);
      }

      const diff = resetTime - now;

      // Calculate hours, minutes, and seconds remaining
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((diff / (1000 * 60)) % 60);
      const seconds = Math.floor((diff / 1000) % 60);

      setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    };

    calculateTimeRemaining(); // Initial call

    // Update every second
    const intervalId = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);

  return (
    <div>
      <p>Website is temporarily down. Please come back in: {timeRemaining}</p>
    </div>
  );
}

export default QuotaResetTimer;
