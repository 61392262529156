import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import styles from './ReviewsPage.module.css'; // CSS module
import { Navbar } from '../Navbar/bar';

export const ReviewsPage = () => {
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(true); // Loading state
    const db = getFirestore();

    useEffect(() => {
        const fetchReviews = async () => {
            setLoading(true); // Set loading to true before fetching
            try {
                const querySnapshot = await getDocs(collection(db, 'reviews'));
                const reviewsData = querySnapshot.docs.map(doc => {
                    const data = doc.data();
                    // Convert the timestamp to a Date object and format it
                    const date = data.createdAt?.toDate(); // Firestore timestamp to JS Date
                    const formattedDate = date ? date.toLocaleDateString() : 'Unknown Date';
                    
                    return {
                        id: doc.id,
                        ...data,
                        formattedDate,
                    };
                });
                setReviews(reviewsData);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            } finally {
                setLoading(false); // Set loading to false once fetch is complete
            }
        };
        fetchReviews();
    }, [db]);

    const renderStars = (rating) => {
        return (
            <div className={styles.starRating}>
                {[1, 2, 3, 4, 5].map((star) => (
                    <span key={star} className={`${styles.star} ${rating >= star ? styles.selected : ''}`}>
                        ★
                    </span>
                ))}
            </div>
        );
    };

    const toggleReadMore = (index) => {
        setReviews((prevReviews) =>
            prevReviews.map((review, i) => {
                if (i === index) {
                    return { ...review, isExpanded: !review.isExpanded };
                }
                return review;
            })
        );
    };

    return (
        <div>
            <Navbar />
            <div className={styles.reviewsPage}>
                <h2 className={styles.pageTitle}>Customer Reviews</h2>

                {loading ? (
                    <p className={styles.loading}>Loading reviews...</p> // Loading state display
                ) : (
                    <div className={styles.reviewsList}>
                        {reviews.length > 0 ? (
                            reviews.map((review, index) => (
                                <div key={review.id} className={styles.reviewItem}>
                                    <div className={styles.reviewHeader}>
                                        {renderStars(review.rating)}
                                        <p className={styles.reviewAuthor}>- {review.author}</p>
                                        <p className={styles.reviewDate}>{review.formattedDate}</p>
                                    </div>
                                    <p className={styles.reviewContent}>
                                        "{review.content.substring(0, review.isExpanded ? review.content.length : 100)}"
                                        {review.content.length > 100 && (
                                            <span
                                                onClick={() => toggleReadMore(index)}
                                                className={styles.readMore}
                                            >
                                                {review.isExpanded ? ' Read Less' : '... Read More'}
                                            </span>
                                        )}
                                    </p>
                                </div>
                            ))
                        ) : (
                            <p className={styles.noReviews}>No reviews available.</p>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReviewsPage;
