import React, { useState, useRef, useEffect } from 'react'; // Added useEffect, useMemo
import CountdownTimer from './AdminCountAuthFirestore/CountdownTimer';
import { FaCopy, FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Imported arrow icons

export const ProxyList = ({
  displayedProxies,
  searchTerm,
  setSearchTerm,
  handleRemoveProxy,
  currentRegeneratingProxyId,
  isRegenerating,
  proxyCooldowns,
  setCurrentRegeneratingProxyId,
  // Removed duplicate parameter:
  // setSelectedRegenerateIP,
  availableIPs,
  getLastSegment,
  isLoading,
  handleRegenerateProxy,
  selectedRegenerateIP,
  setSelectedRegenerateIP,
  handleCountdownEnd,
  setMessage,
  setMessageType,
}) => {
  const [protocolFilter, setProtocolFilter] = useState('All'); // Added protocolFilter state
  const [copied, setCopied] = useState({}); // New state to track copied fields
  const proxyListRef = useRef(null); // Reference to proxy list
  const [canScrollLeft, setCanScrollLeft] = useState(false); // New state
  const [canScrollRight, setCanScrollRight] = useState(false); // New state

  useEffect(() => {
    const proxyList = proxyListRef.current; // Copy to local variable

    const checkScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = proxyList;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
    };
    
    checkScroll(); // Initial check

    proxyList.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);

    return () => {
      proxyList.removeEventListener('scroll', checkScroll);
      window.removeEventListener('resize', checkScroll);
    };
  }, []);

  const copyToClipboard = (text, proxyId, field) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopied(prev => ({ ...prev, [`${proxyId}-${field}`]: true }));
      setTimeout(() => {
        setCopied(prev => ({ ...prev, [`${proxyId}-${field}`]: false }));
      }, 2000); // "Copied" message will disappear after 2 seconds
    }).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const scrollLeft = () => {
    proxyListRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    proxyListRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };

  // Add a uniqueProxies constant to filter out duplicates
  const uniqueProxies = displayedProxies.filter(
    (proxy, index, self) => self.findIndex(p => p.id === proxy.id) === index
  );

  const handleRegenerate = (proxy, oldIp, protocol) => {
    // Check if selected IP is FTP
    const selectedIpData = availableIPs.find((ip) => ip.ip === selectedRegenerateIP);
    
    // If user selects FTP server but proxy is not FTP, show error
    if (selectedIpData?.is_ftp && !proxy.isFtp) {
      setMessage("You can only regenerate on FTP servers if you have an FTP proxy.");
      setMessageType("error");
      return;
    }
  
    const selectedIpIsFtp = selectedIpData?.is_ftp || false;

    // Add validation for l2tp and pptp protocols
    if ((protocol === 'l2tp' || protocol === 'pptp') && !selectedRegenerateIP) {
      setMessage("Please select a server");
      setMessageType("error");
      return;
    }

    handleRegenerateProxy(proxy.id, oldIp, selectedRegenerateIP, protocol, selectedIpIsFtp);
  };

  return (
    <>
      <input
        className="search-input"
        type="text"
        placeholder="Search by Username"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="protocol-container">
        <select
            className="protocol-filter"
            value={protocolFilter}
            onChange={(e) => setProtocolFilter(e.target.value)}
        >
            <option value="All">All</option>
            <option value="Socks5">socks5</option>
            <option value="l2tp">l2tp</option>
            <option value="pptp">pptp</option>
        </select>
      </div>

      <div className="proxy-list-container">
        {canScrollLeft && (
          <button className="scroll-button left" onClick={scrollLeft}>
            <FaArrowLeft />
          </button>
        )}
        <div className="proxy-list" ref={proxyListRef}>
          {uniqueProxies
            .filter((proxy) =>
              // Add null checks for username and protocol
              (proxy.username?.toLowerCase() || '').includes((searchTerm || '').toLowerCase()) &&
              (protocolFilter === 'All' || (proxy.protocol?.toLowerCase() || '') === protocolFilter.toLowerCase())
            )
            .sort((a, b) => {
              if (a.status === 'expired' && b.status !== 'expired') {
                return -1;
              } else if (a.status !== 'expired' && b.status === 'expired') {
                return 1;
              }
              return 0;
            })
            .map((proxy) => (
              <div
                key={proxy.id}
                className={`proxy-item ${proxy.status === 'expired' ? 'expired' : ''}`}
                style={{
                  border: proxy.status === 'expired' ? '' : '1px solid black',
                }}
              >
                {/* Proxy Details */}
                <div className="proxy-details">
                  <p>
                    Status: <span style={{ color: proxy.status === 'expired' ? 'red' : 'green' }}>{proxy.status}</span>
                  </p>
                  {proxy.protocol && <p>Protocol: {proxy.protocol}</p>}
                  <p>
                    {proxy.isFtp === true && <p className='ftp-status'>FTP</p>}
                    IP: {proxy.ip} 
                    <div className="copy-container"> {/* Added container for positioning */}
                      <button
                        className="copy-button"
                       s onClick={() => copyToClipboard(proxy.ip, proxy.id, 'ip')}
                        aria-label="Copy IP"
                        title="Copy IP to clipboard"
                      >
                        <FaCopy className="copy-icon" /> {/* Added class for styling */}
                      </button>
                      {copied[`${proxy.id}-ip`] && <span className="copied-text">Copied</span>}
                    </div>
                  </p>
                  { proxy.port && <p>
                    Port: {proxy.port}
                    <div className="copy-container"> {/* Added container for positioning */}
                      <button
                        className="copy-button"
                        onClick={() => copyToClipboard(proxy.port, proxy.id, 'port')}
                        aria-label="Copy Port"
                        title="Copy Port to clipboard"
                      >
                        <FaCopy className="copy-icon" /> {/* Added class for styling */}
                      </button>
                      {copied[`${proxy.id}-port`] && <span className="copied-text">Copied</span>}
                    </div>
                  </p>}
                  <p>
                    Username: {proxy.username} 
                    <div className="copy-container"> {/* Added container for positioning */}
                      <button
                        className="copy-button"
                        onClick={() => copyToClipboard(proxy.username, proxy.id, 'username')}
                        aria-label="Copy Username"
                        title="Copy Username to clipboard"
                      >
                        <FaCopy className="copy-icon" /> {/* Added class for styling */}
                      </button>
                      {copied[`${proxy.id}-username`] && <span className="copied-text">Copied</span>}
                    </div>
                  </p>
                  <p>
                    Password: {proxy.password} 
                    <div className="copy-container"> {/* Added container for positioning */}
                      <button
                        className="copy-button"
                        onClick={() => copyToClipboard(proxy.password, proxy.id, 'password')}
                        aria-label="Copy Password"
                        title="Copy Password to clipboard"
                      >
                        <FaCopy className="copy-icon" /> {/* Added class for styling */}
                      </button>
                      {copied[`${proxy.id}-password`] && <span className="copied-text">Copied</span>}
                    </div>
                  </p>

                  <p>Speed: {proxy.speed} Mbps</p>
                  <p>Generated: {new Date(proxy.generatedDate).toLocaleString()}</p>
                  <p>Expiry: {new Date(proxy.expiryDate).toLocaleString()}</p>
                </div>

                <div className="proxy-actions">
                  {proxy.status === 'expired' ? (
                    <button onClick={() => handleRemoveProxy(proxy.id)} className="remove-button-rm">
                      Remove
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          if (currentRegeneratingProxyId === proxy.id) {
                            setCurrentRegeneratingProxyId(null);
                            setSelectedRegenerateIP('');
                          } else {
                            setCurrentRegeneratingProxyId(proxy.id);
                            setSelectedRegenerateIP('');
                          }
                        }}
                        disabled={isRegenerating || proxyCooldowns[proxy.id]}
                        className="regenerate-button-1"
                      >
                        {currentRegeneratingProxyId === proxy.id ? null : 'Regenerate'}
                      </button>
                      {proxy.countdownEnd && (
                        <CountdownTimer
                          countdownEnd={proxy.countdownEnd}
                          onCountdownEnd={() => handleCountdownEnd(proxy.id)}
                        />
                      )}
                    </>
                  )}
                </div>

                {/* Regeneration Section */}
                {currentRegeneratingProxyId === proxy.id && (
                  <div className="regeneration-section">
                    <p>Please disconnect the proxy from your device before regenerating.</p>
                    <select
                      id="serverSelect"
                      value={selectedRegenerateIP}
                      onChange={(e) => setSelectedRegenerateIP(e.target.value)}
                    >
                      {proxy.protocol === 'l2tp' || proxy.protocol === 'pptp' ? (
                        <option value="">Select server</option>
                      ) : (
                        <option value="">Select server (Optional)</option>
                      )}
                      {availableIPs
                        .filter(ipData => 
                          proxy.protocol === 'l2tp' || proxy.protocol === 'pptp' 
                            ? ipData.for_l2tp_pptp 
                            : true
                        )
                        .map((ipData) => (
                          <option key={ipData.ip} value={ipData.ip}>
                            {ipData.is_ftp ? 'FTP: ' : 'Server: '}
                            {getLastSegment(ipData.ip)} - recommended for {ipData.speed}
                          </option>
                        ))}
                    </select>
                    <div className="regeneration-buttons">
                      <button
                        className="regenerate-button-2"
                        onClick={() => handleRegenerate(proxy, proxy.ip, proxy.protocol)} // Pass protocol
                        disabled={isLoading || isRegenerating}
                      >
                        {isLoading ? 'Regenerating...' : 'Confirm Regenerate'}
                      </button>
                      <button
                        className='close-button'
                        onClick={() => {
                          setCurrentRegeneratingProxyId(null);
                          setSelectedRegenerateIP('');
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        {canScrollRight && (
          <button className="scroll-button right" onClick={scrollRight}>
            <FaArrowRight />
          </button>
        )}
      </div>
    </>
  );
};