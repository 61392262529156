import React, { useState, useEffect, useCallback } from 'react';
import { getFirestore, collection, addDoc, getDocs, query, where, orderBy, limit, getDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import styles from './ReviewPopup.module.css'; // Importing CSS

export const ReviewPopup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [review, setReview] = useState('');
    const [rating, setRating] = useState(0);
    const [ratingError, setRatingError] = useState('');
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [thankYouMessage, setThankYouMessage] = useState(false);
    const [charCount, setCharCount] = useState(0);
    const charLimit = 250;
    const [userName, setUserName] = useState('Anonymous');


    const db = getFirestore();
    const auth = getAuth();



    useEffect(() => {
        const fetchUsername = async () => {
            const user = auth.currentUser;
            if (user) {
                try {
                    const userDoc = await getDoc(doc(db, 'users', user.uid));
                    if (userDoc.exists()) {
                        const data = userDoc.data();
                        setUserName(data.username || 'Anonymous'); // Assuming 'username' is the field in Firestore
                    }
                } catch (error) {
                    console.error('Error fetching username from database:', error);
                }
            }
        };
    
        fetchUsername();
    }, [auth, db]);

    // Function to check if 24 hours have passed since the proxy was generated
    const check24HoursSinceProxy = useCallback(async () => {
        const user = auth.currentUser;
        if (user) {
            try {
                // Query to get the most recent proxy generated by the user, ordered by 'generatedDate'
                const proxyQuery = query(
                    collection(db, 'users', user.uid, 'proxies'),
                    orderBy('generatedDate', 'desc'),
                    limit(1) // Only get the most recent proxy
                );
                const proxySnapshot = await getDocs(proxyQuery);
                
                if (!proxySnapshot.empty) {
                    const proxyData = proxySnapshot.docs[0].data();
                    const generatedDate = new Date(proxyData.generatedDate); // Convert string timestamp to Date object
                    const now = new Date();
                    
                    // Calculate the difference in seconds
                    const secondsDifference = (now - generatedDate) / 1000;
                    
                    return secondsDifference >= 86400; // For testing, use 24 hours (adjust for your actual use case)
                }
            } catch (error) {
                console.error('Error checking proxy generated date:', error);
            }
        }
        return false;
    }, [auth, db]);

    const shouldShowPopup = () => {
        const laterDate = localStorage.getItem('popupLater');
        if (laterDate) {
            return new Date() > new Date(laterDate); // Only show if 7 days have passed
        }
        return true; // No later set, show popup
    };

    useEffect(() => {
        const checkProxiesAndReviews = async () => {
            const user = auth.currentUser;
            if (user) {
                // Check if the user has already submitted a review
                const reviewQuery = query(collection(db, 'reviews'), where('userId', '==', user.uid));
                const reviewSnapshot = await getDocs(reviewQuery);

                // If the user has already submitted a review, do not show the popup
                if (!reviewSnapshot.empty) {
                    setSubmitted(true);
                    return;
                }

                // Check if the user has at least one generated proxy and 24 hours have passed
                const has24HoursPassed = await check24HoursSinceProxy();

                if (has24HoursPassed && shouldShowPopup()) {
                    // Show popup after 5 seconds if 24 hours have passed
                    const popupTimeout = setTimeout(() => setShowPopup(true), 5000);
                    return () => clearTimeout(popupTimeout);
                } else {
                }
            }
        };

        // Check if popup should appear
        checkProxiesAndReviews();
    }, [auth, check24HoursSinceProxy, db]); // Include check24HoursSinceProxy in dependencies

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if a rating is selected
        if (rating === 0) {
            setRatingError('Please select a rating');
            return;
        }

        setLoading(true);
        const user = auth.currentUser;
        if (user) {
            try {
                await addDoc(collection(db, 'reviews'), {
                    author: userName,
                    content: review,
                    rating: rating,
                    userId: user.uid,
                    createdAt: new Date(),
                });
                setSubmitted(true);
                setShowPopup(false);
                setThankYouMessage(true); // Show thank you message
            } catch (error) {
                console.error('Error saving review: ', error);
            } finally {
                setLoading(false);
            }
        }
    };

    // Fix in handleLater function to ensure popup closes when "Later" is clicked
const handleLater = () => {
    setShowPopup(false); // Close the popup immediately
    // Store a flag in local storage to not show the popup for 1 day
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 1); // Change delay to 1 day
    localStorage.setItem('popupLater', futureDate); // Store the later date in localStorage

    sessionStorage.removeItem('showingPopup'); // Ensure the popup state is removed from sessionStorage
};

// Modify useEffect to ensure proper tracking in sessionStorage
useEffect(() => {
    const wasShowingPopup = sessionStorage.getItem('showingPopup');
    if (wasShowingPopup === 'true') {
        setShowPopup(true); // Re-show popup if previously open before page reload
    }

    // Store popup state in sessionStorage while popup is open
    if (showPopup) {
        sessionStorage.setItem('showingPopup', 'true');
    } else {
        sessionStorage.removeItem('showingPopup'); // Remove state if popup is closed
    }
}, [showPopup]);


    const handleReviewChange = (e) => {
        const inputValue = e.target.value;
        if (inputValue.length <= charLimit) {
            setReview(inputValue);
            setCharCount(inputValue.length);
        }
    };

    if (submitted) return null; // Hide popup if the user has already submitted a review

    return showPopup ? (
        <div className={styles.popupOverlay}>
            <div className={styles.popupContent}>
                <h2 className={styles.popupTitle}>Leave a Public Review</h2>
                <form onSubmit={handleSubmit} className={styles.reviewForm}>
                    <textarea
                        value={review}
                        onChange={handleReviewChange}
                        placeholder="Write your review here..."
                        className={styles.reviewInput}
                        maxLength={charLimit}
                        required
                    />
                    <p className={styles.charCount}>{charCount}/{charLimit}</p>

                    <div className={styles.rating}>
                        <p>Select a rating:</p>
                        {[1, 2, 3, 4, 5].map((star) => (
                            <span
                                key={star}
                                className={`${styles.star} ${rating >= star ? styles.selected : ''}`}
                                onClick={() => {
                                    setRating(star);
                                    setRatingError(''); // Clear the error message if a rating is selected
                                }}
                            >
                                ★
                            </span>
                        ))}
                    </div>

                    {ratingError && <p className={styles.ratingError}>{ratingError}</p>}

                    <button type="submit" className={styles.submitButton} disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit Review'}
                    </button>
                    <button type="button" className={styles.closeButton} onClick={handleLater}>
                        Later
                    </button>
                </form>
            </div>
            {thankYouMessage && <p className={styles.thankYouMessage}>Thank you for your feedback!</p>}
        </div>
    ) : null;
};

export default ReviewPopup;
