import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { db } from '../../config/firebase'; // Add this import
import { collection, query, where, getDocs } from 'firebase/firestore'; // Add this import

// Adjust the modal component
const FtpLinksModal = ({ isOpen, onClose, ftpLinks }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    // Add auto-scroll functionality when modal opens
    if (isOpen && modalRef.current) {
      // Small delay to ensure modal is rendered
      setTimeout(() => {
        modalRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest'
        });
      }, 100);
    }
  }, [isOpen]);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()} ref={modalRef}>
        {/* Add mobile close button */}
        <button className="mobile-close-btn" onClick={onClose}>×</button>
        <h3>Available FTP On This Server</h3>
        {ftpLinks.length > 0 ? (
          <>
            <ul>
              {ftpLinks.map((link, index) => {
                const parts = link.split(': ').map(part => part.trim());
                const label = parts.length > 1 ? parts[0] : null;
                const url = parts.length > 1 ? parts[1] : parts[0];
                return (
                  <li key={index}>
                    {label && <span>{label}: </span>}
                    {url && (
                      <a 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        style={{
                          color: '#007bff',
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                        onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
                        onMouseOut={(e) => e.target.style.textDecoration = 'none'}
                      >
                        {url}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
            <p>There May be More FTP On this Server</p>
          </>
        ) : (
          <p>No FTP links available for this server.</p>
        )}
      </div>
    </div>
  );
};

export const InputSection = ({
  usernameInput,
  setUsernameInput,
  password,
  setPassword,
  speed,
  setSpeed,
  expiry,
  setExpiry,
  selectedIP,
  setSelectedIP,
  availableIPs,
  getLastSegment,
  price,
  setPrice, // Add this line
  isLoading,
  isRegenerating,
  handleGenerateProxy,
  protocol,
  setProtocol,
  isFetchingIPs,
  ftpEnabled, // Add this prop
  setFtpEnabled, // Add this prop
}) => {
  // Remove the commented out useState if not needed
  // Add FTP state
  // const [ftpEnabled, setFtpEnabled] = useState(false);

  // Add new state variables
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ftpLinks, setFtpLinks] = useState([]);

  // Filter available IPs based on ftpEnabled and protocol
  const filteredIPs = useMemo(() => {
    let ips = availableIPs;

    if (protocol === 'pptp' || protocol === 'l2tp') {
      ips = ips.filter((ip) => ip.for_l2tp_pptp);
    }

    if (ftpEnabled) {
      ips = ips.filter((ip) => ip.is_ftp);
    }

    return ips;
  }, [availableIPs, ftpEnabled, protocol]);

  // Define the selectedIpIsFtp variable to determine if the selected IP has FTP enabled
  const selectedIpIsFtp = useMemo(() => {
    return availableIPs.find((ip) => ip.ip === selectedIP)?.is_ftp || false;
  }, [availableIPs, selectedIP]);

  // Function to fetch FTP links
  const fetchFtpLinks = async (ip) => {
    if (!ip) return;
    
    try {
      const routersRef = collection(db, 'routers');
      const q = query(routersRef, where('ip', '==', ip));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const routerData = querySnapshot.docs[0].data();
        // Check if ftpLinks exists and is an array
        if (routerData.ftp_links && Array.isArray(routerData.ftp_links)) {
          setFtpLinks(routerData.ftp_links);
          console.log('Found FTP links:', routerData.ftp_links); // Debug log
        } else {
          console.log('No FTP links found in router data:', routerData); // Debug log
          setFtpLinks([]);
        }
      } else {
        console.log('No router found with IP:', ip); // Debug log
        setFtpLinks([]);
      }
    } catch (error) {
      console.error('Error fetching FTP links:', error);
      setFtpLinks([]);
    }
  };

  // Update FTP links when IP changes
  useEffect(() => {
    if (selectedIP && (ftpEnabled || selectedIpIsFtp)) {
      fetchFtpLinks(selectedIP);
    }
  }, [selectedIP, ftpEnabled, selectedIpIsFtp]);

  // Update the price calculation
  useEffect(() => {
    if (!speed || !expiry) {
      setPrice(0);
      return;
    }

    const speedMbps = parseInt(speed, 10);
    const expiryDays = parseInt(expiry, 10);
    let fixedPrice = 0;

    if (!isNaN(speedMbps)) {
      if (protocol === 'pptp' || protocol === 'l2tp') {
        // Pricing for PPTP or L2TP
        if (speedMbps === 100) fixedPrice = 300;
        else if (speedMbps === 50) fixedPrice = 200;
        else if (speedMbps === 30) fixedPrice = 150;
        else if (speedMbps === 20) fixedPrice = 120;
      } else {
        // Existing pricing for other protocols
        if (expiryDays === 30) {
          if (speedMbps === 100) fixedPrice = 150;
          else if (speedMbps === 60) fixedPrice = 130;
          else if (speedMbps === 50) fixedPrice = 100;
          else if (speedMbps === 40) fixedPrice = 90;
          else if (speedMbps === 30) fixedPrice = 70;
          else if (speedMbps === 20) fixedPrice = 50;
        } else if (expiryDays === 15) {
          if (speedMbps === 100) fixedPrice = 120;
          else if (speedMbps === 60) fixedPrice = 100;
          else if (speedMbps === 50) fixedPrice = 80;
          else if (speedMbps === 40) fixedPrice = 70;
          else if (speedMbps === 30) fixedPrice = 60;
          else if (speedMbps === 20) fixedPrice = 40;
        } else if (expiryDays === 7) {
          if (speedMbps === 100) fixedPrice = 100;
          else if (speedMbps === 60) fixedPrice = 90;
          else if (speedMbps === 50) fixedPrice = 70;
          else if (speedMbps === 40) fixedPrice = 60;
          else if (speedMbps === 30) fixedPrice = 50;
          else if (speedMbps === 20) fixedPrice = 30;
        }
      }
    }

    // Increase price by 30 if selected IP is FTP
    if (selectedIpIsFtp) {
      fixedPrice += 30;
    }

    setPrice(fixedPrice);
  }, [speed, expiry, protocol, selectedIP, availableIPs, setPrice, selectedIpIsFtp]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [isModalOpen]);

  return (
    <div className="input-container">
      <div>
        <input
          type="text"
          placeholder="Proxies Username"
          value={usernameInput}
          onChange={(e) => setUsernameInput(e.target.value)}
          maxLength={10} // Limit username to 10 characters
        />
        <span className="char-remaining">
          {usernameInput.length < 10
            ? `${10 - usernameInput.length} characters remaining!`
            : "Character limit reached!"}
        </span>
      </div>
      <div>
        <input
          type="password"
          placeholder="Proxies Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          maxLength={10} // Limit password to 10 characters
        />
        <span className="char-remaining">
          {password.length < 10
            ? `${10 - password.length} characters remaining!`
            : "Character limit reached!"}
        </span>
      </div>

      {/* Speed Dropdown */}
      <select className='drop-down' value={speed} onChange={(e) => setSpeed(e.target.value)}>
        <option value="">Select Speed (Mbps)</option>
        {protocol === 'pptp' || protocol === 'l2tp' ? (
          [100, 50, 30, 20].map((value) => (
            <option key={value} value={value}>
              {value} Mbps
            </option>
          ))
        ) : (
          [100, 60, 50, 40, 30, 20].map((value) => (
            <option key={value} value={value}>
              {value} Mbps
            </option>
          ))
        )}
      </select>

      {/* Expiry Dropdown */}
      <select
        className='drop-down' 
        value={expiry}
        onChange={(e) => setExpiry(e.target.value)}
        disabled={protocol === 'pptp' || protocol === 'l2tp'} // Disable when protocol is pptp or l2tp
      >
        <option value="">Select Expiry (Days)</option>
        <option value="30">30 Days</option>
        <option value="15">15 Days</option>
        <option value="7">7 Days</option>
      </select>

      {/* IP Selection Dropdown */}
      <select
      className='drop-down'
        value={selectedIP}
        onChange={(e) => setSelectedIP(e.target.value)}
        disabled={isFetchingIPs || isLoading}
      >
        {protocol === 'l2tp' || protocol === 'pptp' || ftpEnabled ? (
          <option value=''>Select server(Nessessary)</option>
        ) : (
          <option value=''>Select server (Optional)</option>
        )}
        {filteredIPs.map((ip) => (
          <option key={ip.ip} value={ip.ip}>
            {ip.is_ftp 
              ? `Server with FTP: ${getLastSegment(ip.ip)}${ip.speed ? ` (Recommended for ${ip.speed})` : ''}` 
              : `Server: ${getLastSegment(ip.ip)}${ip.speed ? ` (Recommended for ${ip.speed})` : ''}`}
          </option>
        ))}
      </select>
      {/* Replace the button with clickable text */}
      {selectedIpIsFtp && (
        <div 
          className="ftp-services-overlay" 
          onClick={() => setIsModalOpen(true)}
        >
          Click me to view FTP links on this server.
        </div>
      )}

      {/* FTP selection */}
      <div className='ftp-selection'>
        <div className='ftp-option'>
          <input
            type="checkbox"
            id="ftpCheckbox" // Added id
            checked={ftpEnabled}
            onChange={(e) => setFtpEnabled(e.target.checked)}
          />
          <label htmlFor="ftpCheckbox" title="filter out ftp ip only">FTP</label>
          {ftpEnabled && <div className="ftp-overlay">Showing servers with FTP</div>}
        </div>
      </div>
      {/* Protocol Selection */}
      <div className="protocol-selection">
        <div className="protocol-option">
          <input
            type="checkbox"
            checked={protocol === 'socks5'}
            onChange={() => setProtocol('socks5')}
          />
          <label>Socks5</label>
        </div>
        <div className="protocol-option">
          <input
            type="checkbox"
            checked={protocol === 'l2tp'}
            onChange={() => setProtocol('l2tp')}
          />
          <label>L2TP</label>
        </div>
        <div className="protocol-option">
          <input
            type="checkbox"
            checked={protocol === 'pptp'}
            onChange={() => setProtocol('pptp')}
          />
          <label>PPTP</label>
        </div>
      </div>

      <p className="price">
        Price: {price} Taka
        {(selectedIpIsFtp && speed) && (
          <span className="price-overlay"> {price - 30} + 30 for FTP</span>
        )}
      </p>

      <button 
        className="generate-button" onClick={() => handleGenerateProxy(ftpEnabled)} disabled={isLoading || isRegenerating}>
        {isLoading ? "Generating..." : "Generate Proxy"}
      </button>

      <FtpLinksModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        ftpLinks={ftpLinks}
      />
    </div>
  );
};

InputSection.propTypes = {
  // Define prop types if not already defined
  usernameInput: PropTypes.string.isRequired,
  setUsernameInput: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  speed: PropTypes.string.isRequired,
  setSpeed: PropTypes.func.isRequired,
  expiry: PropTypes.string.isRequired,
  setExpiry: PropTypes.func.isRequired,
  selectedIP: PropTypes.string.isRequired,
  setSelectedIP: PropTypes.func.isRequired,
  availableIPs: PropTypes.array.isRequired,
  getLastSegment: PropTypes.func.isRequired,
  price: PropTypes.number.isRequired,
  setPrice: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRegenerating: PropTypes.bool.isRequired,
  handleGenerateProxy: PropTypes.func.isRequired,
  protocol: PropTypes.string.isRequired,
  setProtocol: PropTypes.func.isRequired,
  isFetchingIPs: PropTypes.bool.isRequired,
  ftpEnabled: PropTypes.bool.isRequired,
  setFtpEnabled: PropTypes.func.isRequired,
};