import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';
import styles from './Navbar.module.css';

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuVariants = {
    closed: {
      opacity: 0,
      y: "-10%",  // Reduced travel distance
      transition: {
        duration: 0.2  // Reduced duration
      }
    },
    open: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.2  // Reduced duration
      }
    }
  };

  const linkVariants = {
    closed: { y: -10, opacity: 0 },  // Changed from x to y, reduced distance
    open: (i) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: i * 0.05,  // Reduced delay between items
        duration: 0.15    // Faster individual animations
      },
    }),
  };

  const menuItems = [
    { to: "/", label: "Home" },
    { to: "/tools", label: "Tools" },
    { to: "/tutorials", label: "Tutorials" },
    { to: "/pricing", label: "Pricing" },
    { to: "/reviews", label: "Reviews" },
    { to: "/contact", label: "Contact" },
    { to: "/about", label: "About" }
  ];

  return (
    <nav className={styles.navbar}>
      {/* Mobile View */}
      <div className={styles.logo}>
        <Link to="/">SPEED</Link>
      </div>

      <motion.button
        className={styles.menuButton}
        onClick={() => setIsOpen(!isOpen)}
        whileTap={{ scale: 0.95 }}
      >
        {isOpen ? <FaTimes /> : <FaBars />}
      </motion.button>

      {/* Desktop View */}
      <div className={styles.desktopMenu}>
        <ul className={styles.menu}>
          {menuItems.map((item) => (
            <li key={item.to}>
              <Link to={item.to}>{item.label}</Link>
            </li>
          ))}
        </ul>
        <div className={styles.buttons}>
          <Link to="/login"><button>Login</button></Link>
          <Link to="/signup"><button>Signup</button></Link>
        </div>
      </div>

      {/* Mobile Menu */}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className={styles.mobileMenu}
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
          >
            <motion.ul className={styles.menu}>
              {menuItems.map((item, i) => (
                <motion.li
                  key={item.to}
                  custom={i}
                  variants={linkVariants}
                  initial="closed"
                  animate="open"
                >
                  <Link 
                    to={item.to}
                    onClick={() => setIsOpen(false)}
                  >
                    {item.label}
                  </Link>
                </motion.li>
              ))}
            </motion.ul>
            <motion.div 
              className={styles.buttons}
              variants={linkVariants}
              custom={menuItems.length}
            >
              <Link to="/login">
                <button onClick={() => setIsOpen(false)}>Login</button>
              </Link>
              <Link to="/signup">
                <button onClick={() => setIsOpen(false)}>Signup</button>
              </Link>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
