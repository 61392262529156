import React, { useState, useEffect } from 'react';
import styles from './AddMoney.module.css';
import { useNavigate } from "react-router-dom";
import { db, auth } from '../../config/firebase';
import { doc, getDoc, setDoc, updateDoc, arrayUnion, writeBatch, increment } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function AddMoney() {
  const [provider, setProvider] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate('/');
    }
  }, [navigate]);

  const showToast = (message, type) => {
    toast(message, {
      type,
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: {
        background: "rgba(0, 0, 0, 0.7)",
        color: "#fff"
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);

    try {
      const userId = auth?.currentUser?.uid;
      if (!userId) {
        showToast('User not authenticated.', 'error');
        setLoading(false);
        return;
      }

      const usedTransactionRef = doc(db, 'usedTransactions', transactionId);
      const usedTransactionDoc = await getDoc(usedTransactionRef);

      if (usedTransactionDoc.exists() && usedTransactionDoc.data().inProcess) {
        showToast('This transaction ID is already being processed.', 'error');
        setLoading(false);
        return;
      } else if (usedTransactionDoc.exists()) {
        showToast('This transaction ID has already been used.', 'error');
        setLoading(false);
        return;
      }

      const transactionRef = doc(db, 'payments', provider, 'transactions', transactionId);
      const transactionDoc = await getDoc(transactionRef);

      if (transactionDoc.exists()) {
        // Only mark as "in process" if the transaction exists
        await setDoc(usedTransactionRef, { transactionId, inProcess: true }, { merge: true });

        const transactionData = transactionDoc.data();
        const amount = transactionData.amount;

        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          const batch = writeBatch(db);
          batch.update(userDocRef, {
            balance: (userData.balance || 0) + amount,
            totalAddedMoney: (userData.totalAddedMoney || 0) + amount,
            totalAddedMoneyHistory: arrayUnion({
              amount,
              provider,
              transactionId,
              timestamp: new Date()
            })
          });

          batch.set(usedTransactionRef, {
            transactionId,
            userId,
            provider,
            amount,
            timestamp: new Date(),
            inProcess: false // Mark as completed
          });

          // Update monthly earnings
          const currentMonth = new Date().toLocaleString('default', { month: 'numeric' });
          const currentYear = new Date().getFullYear().toString();
          const monthlyEarningsRef = doc(db, 'monthlyEarnings', `${currentYear}-${currentMonth}`);
          batch.set(
            monthlyEarningsRef,
            { totalAmount: increment(amount) },
            { merge: true }
          );

          await batch.commit();

          showToast(`Successfully added Tk ${amount} to your account.`, 'success');
          setTimeout(() => navigate('/dashboard'), 2000);
        } else {
          showToast('User not found.', 'error');
          await updateDoc(usedTransactionRef, { inProcess: false });
        }
      } else {
        showToast('Transaction ID not found in the database. Please try again later.', 'error');
        setLoading(false);
      }
    } catch (error) {
      console.error("Error adding money: ", error);
      showToast('An error occurred while adding money.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.addMoneyContainer}>
      <ToastContainer />
      <h1 className={styles.addMoneyText}>Add Money</h1>
      <p className={styles.text1}>
        To add money, send money to this phone number: 
        <p className={styles.number}>01885684277</p>
      </p>
      <p className={styles.text1}>Remember to select the "Send Money" option on your bKash, NAGAD, or Rocket app.</p>
      <p className={styles.text1}>After sending money, select the payment option and enter your transaction ID below.</p>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label>
            Select Provider:
            <select 
              value={provider} 
              onChange={(e) => setProvider(e.target.value)} 
              required
            >
              <option value="">Select Provider</option>
              <option value="bKash">bKash</option>
              <option value="NAGAD">NAGAD</option>
              <option value="Rocket">Rocket</option>
            </select>
          </label>
        </div>
        <div className={styles.formGroup}>
          <label>
            Transaction ID:
            <input 
              type="text" 
              value={transactionId} 
              onChange={(e) => setTransactionId(e.target.value)} 
              required 
            />
          </label>
        </div>
        <button 
          type="submit" 
          className={styles.btnSubmit} 
          disabled={loading} 
        >
          {loading ? 'Processing...' : 'Add Money'}
        </button>
      </form>
    </div>
  );
}

export default AddMoney;
