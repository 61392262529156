import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/bar'; // Adjust the import path as needed
import styles from './Home.module.css';

export function Home() {
  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(styles.visible);
          }
        });
      },
      {
        threshold: 0.1,
        rootMargin: '20px',
      }
    );

    const containers = document.querySelectorAll(`.${styles.contentContainer}`);
    containers.forEach((container) => observer.observe(container));

    observerRef.current = observer;

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  return (
    <div>
      {/* Navbar */}
      <Navbar />

      {/* Main Wrapper */}
      <main className={styles.wrapper}>
        <article className={styles.textContent}>
          <div className={styles.contentContainer}>
            <header>
              <h1>Premium Proxy Services - SOCKS5, L2TP, PPTP</h1>
              <p>
                এই ওয়েবসাইট থেকে SOCKS-5, L2TP এবং PPTP Proxy কিনতে পারবেন BDIX-SPEED bypass করার জন্য।
                বিস্তারিত জানার জন্য{' '}
                <Link className={styles.linlk} to="/tutorials">
                  Tutorials
                </Link>{' '}
                পেজটি দেখুন।
              </p>
            </header>
          </div>

          <div className={styles.contentContainer}>
            <section>
              <h2>এই ওয়েবসাইটের বিশেষ সুবিধাসমূহ</h2>
              <ul>
                <li>Real-Time SOCKS5, L2TP, এবং PPTP Proxy generate এবং regenerate করার সুবিধা।</li>
                <li>এক ক্লিকেই Proxy generate করতে পারবেন, অর্ডার করে অপেক্ষা করতে হবে না।</li>
                <li>নিজের পছন্দমতো username ও password দিয়ে সেটআপ করতে পারবেন।</li>
                <li>সার্ভিসে কোনো সমস্যা হলে regenerate করে server/ip পরিবর্তন করতে পারবেন (1 ক্লিকে)।</li>
                <li>সকল ডিভাইসে সাপোর্ট (Windows, Android, iOS, Mac)</li>
                <li>
                  24/7 Support On{' '}
                  <Link className={styles.linlk} to="/contact">
                    Telegram
                  </Link>
                  !
                </li>
              </ul>
            </section>
          </div>

          <div className={styles.contentContainer}>
            <section>
              <h2>গ্রাহকদের রিভিউ দেখুন</h2>
              <p>
                আমাদের সেবা সম্পর্কে গ্রাহকদের মতামত পড়তে,{' '}
                <Link className={styles.linlk} to="/reviews">
                  Reviews
                </Link>{' '}
                পেজটি দেখুন।
              </p>
            </section>
          </div>

          <div className={styles.contentContainer}>
            <footer>
              <p className={styles.ispWarning}>
                Warning: If Your ISP Is Dot, Amberit, Link3, Carnival, Don't Purchase From This Website.
              </p>
            </footer>
          </div>
        </article>
      </main>
    </div>
  );
}

export default Home;
