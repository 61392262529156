import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Login } from './components/auth/Login';
import { Home } from './components/Homepage/Home/Home';
import { AboutUs } from './components/Homepage/AboutUs/AboutUs';
import { Signup } from './components/auth/Signup';
import { AddMoney } from './components/AddMoney/AddMoney';
import { Earnings } from './components/Earnings/Earnings';
import { Dashboard } from './components/dashboard/Dashboard';
import { ForgotPassword } from './components/auth/ForgotPassword';
import { Tools } from './components/Homepage/Tools/Tools';
import { Contact } from './components/Homepage/Contacts/Contact';
import { Tutorials } from './components/Homepage/Tutorials/Tutorials';
import { PricingPage } from './components/Homepage/Pricing/PricingPage';
import { NotFound } from './components/404/NotFound';
import { ReviewsPage } from './components/Homepage/ReviewsPage/ReviewsPage';
// import { AdminPanel } from "./components/adminPanel/adminPanel";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard/*" element={<Dashboard />} />
          <Route path="/addmoney" element={<AddMoney />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/tools" element={<Tools />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/reviews" element={<ReviewsPage/>} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/tracker" element={<Earnings />} />
          {/* <Route path="/speedpanel" element={<AdminPanel />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
