import React from 'react';
import styles from './AboutUs.module.css';
import Navbar from '../Navbar/bar';

export const AboutUs = () => {
    return (
        <div>
            <Navbar />
            <div className={styles.aboutUs}>
                {/* Main Title */}
                <h1>SpeedBuy - Best BDIX bypass Socks5 Proxy Provider</h1>
                <p>
                    Welcome to <strong>SpeedBuy.xyz</strong>, your one-stop solution for high-speed, reliable <strong>Socks5 Proxy Services</strong> in Bangladesh. 
                    We empower internet users with seamless <strong>BDIX bypass solutions</strong> to enhance browsing, streaming, and overall internet experience. 
                    Our mission is to provide secure, user-focused tools for better connectivity.
                </p>

                {/* What We Offer */}
                <div className={styles.contentSection}>
                    <h2>Our Services</h2>
                    <p>
                        <strong>SpeedBuy.xyz</strong> specializes in offering top-tier proxy services, designed to meet the needs of individuals and businesses in Bangladesh. 
                        Our features include:
                    </p>
                    <ul>
                        <li>Customized <strong>Socks5 proxies</strong> for BDIX bypass.</li>
                        <li>Real-time proxy generation for instant access.</li>
                        <li>Options to select speed limits and validity periods.</li>
                        <li>Enhanced security and privacy for online activities.</li>
                        <li>Flexible plans: 7-day, 15-day, and 30-day options.</li>
                    </ul>
                </div>

                {/* Why Choose Us */}
                <div className={styles.contentSection}>
                    <h2>Why Choose Us?</h2>
                    <p>
                        At <strong>SpeedBuy.xyz</strong>, we understand the importance of a fast and secure internet connection. Here’s why thousands of users trust us:
                    </p>
                    <ul>
                        <li><strong>High-Speed Browsing:</strong> Optimized proxies for faster connections.</li>
                        <li><strong>Reliable BDIX Bypass:</strong> Seamless access to BDIX content.</li>
                        <li><strong>Customizable Options:</strong> Tailor your proxies to suit your needs.</li>
                        <li><strong>Privacy Protection:</strong> Secure your data with encrypted proxies.</li>
                        <li><strong>24/7 Support:</strong> Our team is here to assist you anytime.</li>
                    </ul>
                </div>

                {/* How It Works */}
                <div className={styles.contentSection}>
                    <h2>How It Works</h2>
                    <p>
                        Using <strong>SpeedBuy.xyz</strong> is simple. Follow these steps:
                    </p>
                    <ol>
                        <li>Create an account on our platform.</li>
                        <li>Generate a proxy by choosing a username, password, and speed limit.</li>
                        <li>Select a validity period that fits your needs (7, 15, or 30 days).</li>
                        <li>Start enjoying faster, more secure internet connectivity.</li>
                    </ol>
                    <p>
                        If you need assistance, our platform allows instant proxy regeneration for uninterrupted service.
                    </p>
                </div>

                {/* Mission */}
                <div className={styles.contentSection}>
                    <h2>Our Mission</h2>
                    <p>
                        At <strong>SpeedBuy.xyz</strong>, our mission is to provide reliable, legal, and innovative proxy solutions to internet users in Bangladesh. 
                        We strive to enhance internet performance while maintaining the highest standards of security and privacy.
                    </p>
                </div>

                {/* Call to Action */}
                <div className={styles.cta}>
                    <h3>Ready to Experience Faster Internet?</h3>
                    <p>
                        Join thousands of satisfied users who trust <strong>SpeedBuy.xyz</strong> for their <strong>BDIX Socks5 Proxy</strong> needs.
                        Get started today and unlock a better online experience.
                    </p>
                    <a href="/signup" className={styles.signupBtn}>
                        Sign Up Now
                    </a>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
