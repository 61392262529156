import { useState, useEffect } from "react";
import { Navigate, Link, useNavigate } from "react-router-dom";
import { auth, googleprovider, db } from "../../config/firebase";
import { FaGoogle, FaArrowLeft } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    createUserWithEmailAndPassword,
    signInWithPopup,
    signOut,
    onAuthStateChanged,
    sendEmailVerification
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import styles from './Signup.module.css';

export const Signup = () => {
    const navigate = useNavigate();
    const [signupEmail, setSignupEmail] = useState("");
    const [signupPassword, setSignupPassword] = useState("");
    const [username, setUsername] = useState("");
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showRequirements, setShowRequirements] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState("");

    // Password requirements state
    const [passwordRequirements, setPasswordRequirements] = useState({
        minLength: false,
        hasLowerCase: false,
        hasUpperCase: false,
        hasNumber: false,
        hasSpecialChar: false,
    });

    const getErrorMessage = (error) => {
        switch (error.code) {
            case 'auth/email-already-in-use':
                return 'This email is already registered. Please try logging in instead.';
            case 'auth/invalid-email':
                return 'Please enter a valid email address.';
            case 'auth/operation-not-allowed':
                return 'Email/password sign up is not enabled. Please contact support.';
            case 'auth/weak-password':
                return 'Please choose a stronger password.';
            case 'auth/network-request-failed':
                return 'Network error. Please check your internet connection.';
            default:
                return 'An error occurred during signup. Please try again.';
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await user.reload();
                setIsLoggedIn(user.emailVerified);
                if (!user.emailVerified) {
                }
            } else {
                setIsLoggedIn(false);
            }
        });

        return () => unsubscribe();
    }, []);

    if (isLoggedIn) {
        return <Navigate to="/dashboard" />;
    }

    const saveUserDetails = async (uid, username, email) => {
        const userDocRef = doc(db, "users", uid);
        const userDoc = await getDoc(userDocRef);
        if (!userDoc.exists()) {
            await setDoc(userDocRef, {
                uid,
                username,
                email,
                balance: 0
            });
        }
    };

    const handleSignup = async () => {
        try {
            setIsLoading(true);
            setLoadingMessage("Signing up...");
            
            const userCredential = await createUserWithEmailAndPassword(auth, signupEmail, signupPassword);
            const user = userCredential.user;
            
            setLoadingMessage("Saving user details...");
            await saveUserDetails(user.uid, username, signupEmail);
            
            setLoadingMessage("Sending verification email...");
            await sendEmailVerification(user);
            
            setIsLoading(false);
            setLoadingMessage("");
            toast.success("Signup successful! Verification email sent. Please verify your email.");
            await signOut(auth);
            
            // Add auto-navigation after 10 seconds
            setTimeout(() => {
                navigate('/login');
            }, 20100);
        } catch (err) {
            setIsLoading(false);
            setLoadingMessage("");
            toast.error(getErrorMessage(err), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handleGoogleSignup = async () => {
        try {
            const userCredential = await signInWithPopup(auth, googleprovider);
            const user = userCredential.user;
            await saveUserDetails(user.uid, user.displayName || user.email.split("@")[0], user.email);
            toast.success("Signup with Google successful!");
        } catch (err) {
            toast.error(getErrorMessage(err), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    const handlePasswordFocus = () => setShowRequirements(true);
    const handlePasswordBlur = () => setShowRequirements(false);

    const validatePassword = (password) => {
        setPasswordRequirements({
            minLength: password.length >= 6,
            hasLowerCase: /[a-z]/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasNumber: /[0-9]/.test(password),
            hasSpecialChar: /[@#!%^&]/.test(password),
        });
    };

    return (
        <div>
            <ToastContainer 
                position="top-right"
                autoClose={20000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <button className={styles.backButton}><Link to="/"><FaArrowLeft /> Back</Link></button>
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2>Signup</h2>
                    <div className={styles.inputContainer}>
                        <input
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            placeholder="Email"
                            value={signupEmail}
                            onChange={(e) => setSignupEmail(e.target.value)}
                        />
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            type="password"
                            placeholder="Password"
                            value={signupPassword}
                            onFocus={handlePasswordFocus}
                            onBlur={handlePasswordBlur}
                            onChange={(e) => {
                                setSignupPassword(e.target.value);
                                validatePassword(e.target.value);
                            }}
                        />
                    </div>

                    {/* Password requirements */}
                    {showRequirements && (
                        <div className={styles.passwordRequirements}>
                            <span className={passwordRequirements.minLength ? styles.met : ''}>✔ At least 6 characters</span>
                            <span className={passwordRequirements.hasLowerCase ? styles.met : ''}>✔ Lowercase letter</span>
                            <span className={passwordRequirements.hasUpperCase ? styles.met : ''}>✔ Uppercase letter</span>
                            <span className={passwordRequirements.hasNumber ? styles.met : ''}>✔ Use Numbers</span>
                            <span className={passwordRequirements.hasSpecialChar ? styles.met : ''}>✔ Special characters like @#!%^& etc</span>
                        </div>
                    )}

                    {isLoading ? (
                        <div className={styles.loadingText}>{loadingMessage}</div>
                    ) : (
                        <button className={styles.signupButton} onClick={handleSignup}>Signup</button>
                    )}
                    
                    <button 
                        className={styles.buttonGoogleButton} 
                        onClick={handleGoogleSignup}
                        disabled={isLoading}
                    >
                        <FaGoogle className={styles.googleIcon} /> Signup with Google
                    </button>
                    <Link to="/login" className={styles.signinLink}>Already have an account? Login</Link>
                </div>
            </div>
        </div>
    );
};

export default Signup;
