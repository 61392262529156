
import React from 'react';
import { Link } from 'react-router-dom';
import { FaPowerOff } from 'react-icons/fa';

export const Navbar = ({ handleLogout }) => (
  <div className="navbar">
    <Link to="/addmoney" className="add-money">Add Money</Link>
    <Link to="/" onClick={handleLogout} className="logout">
      <FaPowerOff className="logout-icon" /> Logout
    </Link>
  </div>
);