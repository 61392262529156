import React from 'react';
import Navbar from '../Navbar/bar';
import styles from './Tutorials.module.css'; // Import the CSS module

export const Tutorials = () => {
  return (
    <div>
      <Navbar />
      <div className={styles.tutorialsContainer}>
        <h2 className={styles.heading}>Tutorials</h2>
        <ul className={styles.tutorialsList}>
        <li className={styles.tutorialItem}>
            <h3 className={styles.tutorialTitle}>How To Buy Proxy From speedbuy.xyz</h3>
            <a href="https://youtu.be/GiNHMC6UgRs" target="_blank" rel="noopener noreferrer" className={styles.tutorialLink}>
              Watch on YouTube
            </a>
          </li>
          <li className={styles.tutorialItem}>
            <h3 className={styles.tutorialTitle}>How To Add Money To Your Account</h3>
            <a href="https://youtu.be/x8OyhAz_SW4" target="_blank" rel="noopener noreferrer" className={styles.tutorialLink}>
              Watch on YouTube
            </a>
          </li>
          <li className={styles.tutorialItem}>
            <h3 className={styles.tutorialTitle}>How to Connect to SOCKS-5 Proxy on Windows/Linux</h3>
            <a href="https://youtu.be/QkAo5xt52X8" target="_blank" rel="noopener noreferrer" className={styles.tutorialLink}>
              Watch on YouTube
            </a>
          </li>
          <li className={styles.tutorialItem}>
            <h3 className={styles.tutorialTitle}>How to Connect to SOCKS-5 Proxy on Android/iOS</h3>
            <a href="https://youtu.be/nDGLqbOaVE0" target="_blank" rel="noopener noreferrer" className={styles.tutorialLink}>
              Watch on YouTube
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Tutorials;
